<div class="enote-title" contenteditable="false" *ngIf="!term.is_open_text && !term.initial_component" >
  <span *ngIf="term.name || term.keyword.name" contenteditable="false">
    <span #term_name_span [tooltip]="term.control_value_class"
          [class]="term.control_value_class"
          [class.term]="term.isTermTypeChild()"
          [class.text-bold]="term.isComponentTypeChild()"
          [class.primary-title]="term.component_level == 2"
          [class.secondary-title]="term.component_level == 3"
          [class.tertiary-title]="term.component_level == 4"
          (focus)="handleTerm(term)"
          (blur)="blurTerm()"
          [ngStyle]="{'text-decoration-line': term.term_type == 'keyword' ? 'underline' : ''}"
          [contextMenu]="term.component_type === 'general' ? secondContextMenu : null"
          (click)="term.toggle_control_value(term.defaultTermControl);getResutlData(term.keyword)">
      {{term.controlText}}
    </span>
      <ng-container *ngIf="term.term_type == 'keyword' && term.show_keyword_result">
      <ss-multiselect-dropdown [id]="term.id" [options]="myOptions" [texts]="myTexts" [(ngModel)]="optionsModel"
                               [settings]="selectSettings"
                               (ngModelChange)="OnSelect()">

    </ss-multiselect-dropdown>
        </ng-container>
  </span>
</div>

<ng-container *ngIf="term.hasChildren()">
  <ng-container *ngFor="let child_terms of term.children_groups;let i=index">
    <span [class.d-block]="i > 0">
      <ng-container *ngFor="let child_term of child_terms">
        <app-drafted-enote class="inline-comp mb-sm" *ngIf="!child_term._destroy" [term]="child_term"></app-drafted-enote>
      </ng-container>
    </span>
  </ng-container>
</ng-container>

<span class="enote-control" *ngIf="term.enable_custom_control && term.term_custom_controls.length > 0">
  <span>[</span>
  <ng-container *ngFor="let term_control of term.term_custom_controls; let i=index">
    <app-drafted-enote-controls [term]="term" [term_control]="term_control" [control_index]="i"></app-drafted-enote-controls>
    <span *ngIf="i != (term.term_custom_controls.length -1)">,</span>
  </ng-container>
  <span>]</span>
</span>

<ng-container *ngIf="term.is_open_text">
  <span class="free-text" [textContent]="term.open_text" contenteditable="true"
        (input)="term.open_text=$event.target.textContent"
        (keydown.enter)="addSubComponent($event)" #open_text_span
        (keydown.delete)="onBackSpaceSeparator()">
  </span>
</ng-container>
<context-menu #secondContextMenu>
  <ng-container *ngIf="term.term_type == 'term' && term.keyword">
    <ng-template contextMenuItem (execute)="toggleKeywordTerm($event)">
      Change Term
    </ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>
  </ng-container>
  <ng-container *ngIf="term.term_type == 'term'">
    <ng-template contextMenuItem (execute)="showModal(template)">
      Add Term
    </ng-template>
    <ng-container *ngIf="term.component_type !== 'component'">
      <ng-template contextMenuItem divider="true"></ng-template>
      <ng-template contextMenuItem (execute)="openPropteryModal(termPropertyTemplate)">
        Properties
      </ng-template>
    </ng-container>
  </ng-container>
</context-menu>
<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" (click)="modalRef.hide();resetToken()">
      <i class="fa fa-times fs16"></i>
    </button>
    <h4 class="modal-title text-center">Add Term</h4>
  </div>
  <div class="modal-body">
    <div class="row form-group">
      <div class="col-xs-3"><label class="pt-sm">Search Term</label></div>
      <div class="col-xs-9">
        <input #typeahead [(ngModel)]="token"
               typeaheadWaitMs="800"
               [typeahead]="data_source"
               [typeaheadAsync]="true"
               (typeaheadLoading)="showMessage = false"
               autocomplete="off"
               (typeaheadNoResults)="showMessage = true"
               (typeaheadOnSelect)="typeaheadOnSelectAddTerm($event)"
               [typeaheadOptionsLimit]="20"
               [typeaheadOptionField]="'sname'"
               (keydown)="checkString($event)"
               class="form-control">
        <span *ngIf="save_in_progress"><i class="fa fa-spinner fa-spin"></i></span>
      </div>
    </div>
    <p class="alert alert-warning pt-sm pb-sm m0 mt-lg fs12">If no result found.Please Press ESC to add.</p>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide();resetToken()" class="btn btn-primary">Close</button>
  </div>
</ng-template>
<ng-template #termPropertyTemplate>
  <div class="modal-header">
    <button type="button" class="close pull-right" (click)="propertyModal.hide()">
      <i class="fa fa-times fs16"></i>
    </button>
    <h4 class="modal-title">Term: {{term.name}}</h4>
  </div>
  <div class="modal-body">
    <div class="row form-group">
      <div class="col-xs-4"><label class="pt-sm">Default Control</label></div>
      <div class="col-xs-8">
        <select class="form-control" (change)="onChange($event.target.value)">
          <ng-container *ngFor="let d_control of _enote_service.default_controls">
            <option [selected]="d_control.id == term.defaultTermControl.control_id" [value]="d_control.id">
              {{d_control.name}}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-xs-4"><label class="pt-sm">{{normal_term_control_value_property.value.name}}</label></div>
      <div class="col-xs-4">
        <input #typeahead [(ngModel)]="normal_term_control_value_property.prop_value"
               typeaheadWaitMs="800"
               [typeahead]="first_data_source"
               [typeaheadAsync]="true"
               (typeaheadLoading)="changeTypeaheadLoading($event)"
               autocomplete="off"
               (typeaheadNoResults)="changeTypeaheadNoResults($event)"
               (typeaheadOnSelect)="typeaheadOnFirstSelect($event,normal_term_control_value_property)"
               [typeaheadOptionsLimit]="20"
               [disabled]="term.term_type == 'keyword'"
               [typeaheadOptionField]="'sname'"
               class="form-control">
      </div>
      <div class="col-xs-4">
        <label class="pt-sm fs12">
          <input type="checkbox" [(ngModel)]="normal_term_control_value_property.enable_custom_control">
          &nbsp;Enable Custom Control
        </label>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-xs-4"><label class="pt-sm">{{abnormal_term_control_value_property.value.name}}</label></div>
      <div class="col-xs-4">
        <input #typeahead [(ngModel)]="abnormal_term_control_value_property.prop_value"
               typeaheadWaitMs="800"
               [typeahead]="second_data_source"
               [typeaheadAsync]="true"
               (typeaheadLoading)="changeTypeaheadLoading($event)"
               autocomplete="off"
               (typeaheadNoResults)="changeTypeaheadNoResults($event)"
               [typeaheadOptionsLimit]="20"
               [typeaheadOptionField]="'sname'"
               [disabled]="term.term_type == 'keyword'"
               class="form-control">
      </div>
      <div class="col-xs-4">
        <label class="pt-sm fs12">
          <input type="checkbox" [(ngModel)]="abnormal_term_control_value_property.enable_custom_control">
          &nbsp;Enable Custom Control
        </label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="saveModal(template)" class="btn btn-primary">Save</button>
    <button (click)="cancelModal(template)" class="btn btn-inverse">Cancel</button>
  </div>
</ng-template>

