import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {EnoteService} from '../../services/enote.service';
import {NoteControlsComponent} from '../note-controls/note-controls.component';

@Component({
  selector: 'app-drafted-enote-controls',
  templateUrl: './drafted-enote-controls.component.html',
  styleUrls: ['./drafted-enote-controls.component.scss']
})
export class DraftedEnoteControlsComponent extends NoteControlsComponent implements OnInit {
  @Input() drafUi: boolean;

  constructor(public enoteService: EnoteService, public renderer: Renderer2) {
    super(enoteService, renderer);
  }

  ngOnInit() {
    this.setSettings();
    this.initializeControlValue();
  }

}
