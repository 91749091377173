import { Injectable } from '@angular/core';
import {CustomHttpClient} from '../../services/custom-http-client.service';
import {IntakeFormTemplate} from './intake-form-template';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from '../../services/nullRemover';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntakeFormTemplateService {
  reloadIntakeFormTemplates: Subject<any> = new Subject<any>();

  constructor(private _tokenService: CustomHttpClient) { }

  create(intakeFormTemplate: IntakeFormTemplate): Promise<any> {
    return this._tokenService
      .post('intake_forms', intakeFormTemplate.to_params())
      .toPromise()
      .then(response => response)
  }

  save(intakeFormTemplate: IntakeFormTemplate): Promise<any> {
    if (intakeFormTemplate.is_new_record()) {

      return this.create(intakeFormTemplate);
    }
    else {
      return this.update(intakeFormTemplate);
    }
  }

  update(intakeFormTemplate: IntakeFormTemplate) {
    return this._tokenService
      .put('intake_forms', intakeFormTemplate.to_params())
      .toPromise()
      .then(response => response)
  }

  getIntakeFormTemplates(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('intake_forms', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          intakeFormTemplates: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  getSubmittedIntakeForm(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('intake_forms/submitted_forms', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          intakeFormTemplates: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }
  searchFormTemplates(token: string) {
    let args = new HttpParams();
    args = args.set('token', token);
    return this._tokenService.get('intake_forms/search', {params: args})
      .toPromise()
      .then(response => response)
  }

  sendIntakeFormEmail(template_id: any, patient_id: any) {
      let  params = {intake_form_id: template_id, patient_id: patient_id}
      return this._tokenService.post('intake_forms/send_intake_form', params)
        .toPromise()
        .then(resp => resp)
  }

  getTemplatesForIntake(token: any): Observable<any>   {
    let args = new HttpParams();
    args = args.set('token', token);
    return this._tokenService.get('intake_forms/enote_search', {params: args})
      .map(res => res);
   /* return this._tokenService.get('templates', {params: args})
      .map(res => res);*/
  }
}
