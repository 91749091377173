import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule }     from '@angular/common/http';
// import { SelectModule } from "ng2-select";
// import { NguiDatetimePickerModule } from '@ngui/datetime-picker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { EnoteComponent } from './enote/enote.component'
import { EnoteService } from '../services/enote.service';
import { TermComponent } from './term/term.component';
import { ControlComponent } from './control/control.component';
import { CustomTypeaheadComponent } from './custom_controls/custom-typeahead/custom-typeahead.component';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { ControlPropertyTypeComponent } from './control-property-type/control-property-type.component';
import { SeperatorComponent } from './seperator/seperator.component';
import { SearchableComponent } from './searchable/searchable.component';
import { TemplatesListingComponent } from './templates-listing/templates-listing.component';
import { TemplateNoteComponent } from './template-note/template-note.component';
import { CreateNoteComponent } from './create-note/create-note.component';
import { ControlsSearchableComponent } from './controls-searchable/controls-searchable.component';
import { NoteControlsComponent } from './note-controls/note-controls.component';
import { NoteCustomControlValueComponent } from './note-custom-control-value/note-custom-control-value.component';
import { PatientNoteComponent } from './patient-note/patient-note.component';
import { DraftedEnoteComponent } from './drafted-enote/drafted-enote.component';
import { TemplatesFilterComponent } from './templates-filter/templates-filter.component';
import { TemplatesComponent } from './templates/templates.component';
import { PrintEnoteComponent } from './print-enote/print-enote.component';
import { DraftedEnoteControlsComponent } from './drafted-enote-controls/drafted-enote-controls.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ReadOnlyTemplateComponent } from './read-only-template/read-only-template.component';
import { ReadOnlyControlsComponent } from './read-only-controls/read-only-controls.component';
import { NoteSummaryComponent } from './note-summary/note-summary.component';
import { RichTextEnoteComponent } from './rich-text-enote/rich-text-enote.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import {ContextMenuModule} from '@perfectmemory/ngx-contextmenu';
import { EnoteUiViewComponent } from './enote-ui-view/enote-ui-view.component';
import { TermUiViewComponent } from './enote-ui-view/term-ui-view/term-ui-view.component';
import { ControlUiViewComponent } from './enote-ui-view/control-ui-view/control-ui-view.component';

@NgModule({
  imports: [BsDropdownModule, ContextMenuModule, ScrollingModule,
    BrowserAnimationsModule, CommonModule, MultiselectDropdownModule,
    RouterModule, SharedModule, HttpClientModule, SharedModule, CarouselModule.forRoot(), EditorModule,
  ],
    declarations: [TemplatesListingComponent, EnoteComponent, TermComponent,
      ControlComponent, CustomTypeaheadComponent, ControlPropertyTypeComponent,
      SeperatorComponent, SearchableComponent, TemplatesComponent, TemplateNoteComponent,
      CreateNoteComponent, ControlsSearchableComponent, NoteControlsComponent,
      NoteCustomControlValueComponent, PatientNoteComponent, DraftedEnoteComponent,
      TemplatesFilterComponent,
      PrintEnoteComponent,
      DraftedEnoteControlsComponent,
      ReadOnlyTemplateComponent,
      ReadOnlyControlsComponent,
      NoteSummaryComponent,
      RichTextEnoteComponent,
      EnoteUiViewComponent,
      TermUiViewComponent,
      ControlUiViewComponent],
    exports: [MultiselectDropdownModule, TemplatesListingComponent, EnoteComponent, TermComponent,
      ControlComponent, CustomTypeaheadComponent, ControlPropertyTypeComponent,
      SeperatorComponent, SearchableComponent, TemplatesComponent, TemplateNoteComponent,
      CreateNoteComponent, ControlsSearchableComponent, NoteControlsComponent,
      NoteCustomControlValueComponent, PatientNoteComponent, DraftedEnoteComponent,
      TemplatesFilterComponent,
      PrintEnoteComponent,
      DraftedEnoteControlsComponent,
      ReadOnlyTemplateComponent,
      ReadOnlyControlsComponent, NoteSummaryComponent], providers: [EnoteService]})
export class EnoteModule { }
