import {Component, Injector, OnInit} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {IntakeFormTemplateService} from '../intake-form-template.service';
import {IntakeFormTemplate, IntakeFromStep} from '../intake-form-template';
import {UserService} from '../../../services/user.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { TabsService } from '../../../services/tabs.service';
import {Tab} from '../../../tabs/tab';
import {AlertService} from '../../../_alert';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Component({
  selector: 'app-intake-form-template',
  templateUrl: './intake-form-template.component.html',
  styleUrls: ['./intake-form-template.component.scss']
})
export class IntakeFormTemplateComponent implements OnInit {
  public intakeFormTemplate: IntakeFormTemplate;
  availableSteps: string[] = ['Demographics', 'Current Diagnosis', 'Current Medications', 'Current Allergies', 'Add from Clinical Templates'];
  selectedStep: string | null = null;
  showTemplateSearchField: boolean;
  typeaheadNoResultsTemplate = false;
  typeaheadLoadingTemplate = false;
  public templates: Observable<any[]>;
  public template_search: string;
  constructor(public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public intakeFormTemplateService: IntakeFormTemplateService, public tabsService: TabsService,
              public injector: Injector, public userService: UserService, public alertService: AlertService) {
    this.templates = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.template_search);
    }).pipe(mergeMap((token: string) => this.intakeFormTemplateService.getTemplatesForIntake(token)));
  }
  ngOnInit(): void {
    this.intakeFormTemplate  = this.injector.get('intakeFormTemplate', new IntakeFormTemplate());
    this.availableSteps = this.filterAvailableSteps();
  }

  filterAvailableSteps(): string[] {
    return this.availableSteps.filter(step => {
      return ! this.intakeFormTemplate.steps.some(selectedStep => selectedStep.name === step);
    });
  }

  drop(event: CdkDragDrop<IntakeFromStep[]>) {
    moveItemInArray(this.intakeFormTemplate.steps, event.previousIndex, event.currentIndex);
    this.intakeFormTemplate.steps.forEach((step, index) => step.step_order = index + 1);
  }
  onStepSelected(event: Event) {
    const stepName = (event.target as HTMLSelectElement).value;
    this.selectedStep = stepName;
    if (stepName === 'Add from Clinical Templates') {
      this.showTemplateSearchField = !this.showTemplateSearchField;
      this.selectedStep = '';
    } else {
      this.addStep();
    }
    setTimeout(() => {
      (event.target as HTMLSelectElement).value = '';
    });
  }

  addStep() {
    if (!this.selectedStep || this.selectedStep === 'placeholder') return;
    const existingStep = this.intakeFormTemplate.steps.find(step => step.name === this.selectedStep);

    if (existingStep) {
      // If the step is already present, set _destroy to false
      existingStep._destroy = false;
    } else {
      // If the step is not present, create a new step and add it to the array
      const newStep: IntakeFromStep = new IntakeFromStep();
      newStep.name = this.selectedStep;
      newStep.step_order = this.intakeFormTemplate.steps.length + 1;
      this.intakeFormTemplate.steps.push(newStep);
    }
    this.availableSteps = this.availableSteps.filter(s => s !== this.selectedStep);
    this.selectedStep = '';
  }

  removeStep(index: number) {
    const removedStep = this.intakeFormTemplate.steps[index];
    this.selectedStep = '';
    if (removedStep.id) {
      //this.intakeFormTemplate.steps.filter(a => a.id === removedStep.id)[0]._destroy = true
      removedStep._destroy = true;
    } else {
      this.intakeFormTemplate.steps.splice(index, 1);
    }
    this.availableSteps.push(removedStep.name);
    this.intakeFormTemplate.steps.forEach((step, i) => step.step_order = i + 1);
  }

  save() {
    this.loaderService.small_loder_show();
    this.intakeFormTemplate.practice_id = this.userService.current_user.practices_id[0];
    this.saveObjectCall(this.intakeFormTemplate)
  }

  saveObjectCall(intakeFormTemplate) {
    this.intakeFormTemplateService.save(intakeFormTemplate)
      .then(resp => {
        intakeFormTemplate = this.intakeFormTemplate.load_from_json(resp)
        this.loaderService.small_loder_hide();
        this.intakeFormTemplateService.reloadIntakeFormTemplates.next(1);
        this.alertService.success('Successfully saved', this.alertService.options);
      })
      .catch(resp => {
        this.loaderService.small_loder_hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })

  }

  closeNewForm() {
    if (this.tabsService.active_tab().component === IntakeFormTemplateComponent) {
      this.tabsService.close_tab(this.tabsService.active_tab());
    }
  }

  createCopy(intakeFormTemplate: IntakeFormTemplate) {
    let form = new IntakeFormTemplate().load_from_json(intakeFormTemplate)
    form.id = undefined;
    form.steps.map(step => step.id = undefined);
    this.tabsService.add_tab(new Tab({title: 'Clone Intake Template', icon: 'fa'}, IntakeFormTemplateComponent,
      'copy', {id: form.id, intakeFormTemplate: form}));
  }

  isButtonDisabled(): boolean {
    if (this.intakeFormTemplate.steps.length === 0) {
      return true;
    } else if (this.intakeFormTemplate.steps.length === 1) {
      return this.intakeFormTemplate.steps[0]._destroy;
    } else {
      return false;
    }
  }

  changeTypeaheadLoadingTemplate(e: boolean) {
    this.typeaheadLoadingTemplate = e;
  }

  changeTypeaheadNoResultsTemplate(e: boolean) {
    this.typeaheadNoResultsTemplate = e;
  }

  typeaheadOnTemplateSelect($event: any) {
    const existingStep = this.intakeFormTemplate.steps.find(step => step.enote_template_id === $event.item.id);
    if (existingStep) {
      existingStep._destroy = false;
      this.alertService.error('Template Already Selected', this.alertService.options);

    } else {
      const newStep: IntakeFromStep = new IntakeFromStep();
      newStep.name = $event.item.name;
      newStep.enote_template_id = $event.item.id;
      newStep.step_order = this.intakeFormTemplate.steps.length + 1;
      this.intakeFormTemplate.steps.push(newStep);
    }
    this.template_search = '';
    this.showTemplateSearchField = false;
  }
}
