<span  class="enote-title mt-lg mb-lg" style="display: block;" contenteditable="false" *ngIf="!term.initial_component">

  <span *ngIf="term.is_open_text || term.name || term.keyword.name" contenteditable="false">

    <span #term_name_span
          [class]="term.control_value_class"
          [class.term]="term.isTermTypeChild()"
          [class.text-bold]="term.isComponentTypeChild()"
          [class.primary-title]="term.component_level == 2"
          [class.secondary-title]="term.component_level == 3"
          [class.tertiary-title]="term.component_level == 4"
          [ngStyle]="{'text-decoration-line': term.term_type == 'keyword' ? 'underline' : ''}"
          (click)="term.toggle_control_value(term.defaultTermControl)">
      {{ term.is_open_text ? term.open_text : term.controlText }}
    </span>

    <span *ngIf="term.component_type !== 'component'">
      <ng-container *ngIf="stage == 'drafted_enote'">
        <span *ngIf="term.defaultTermControl">
          <span *ngFor="let controlValue of term.defaultTermControl.term_control_values"
                [hidden]="controlValue.val_name === 'no-observation'">
            <input type="checkbox" class="ml-lg"
                   [checked]="controlValue.recent_value"
                   (change)="onCheckboxChange(controlValue, 'drafted_enote')">
            {{ controlValue.val_name }}
          </span>
        </span>
      </ng-container>
      <ng-container *ngIf="stage == 'create_enote'">
        <span *ngIf="term.defaultTermControl">
          <span  *ngFor="let controlValue of term.defaultTermControl.control.values"
                [hidden]="controlValue.name === 'no-observation'">
            <input type="checkbox" class="ml-lg"
                   [checked]="controlValue.selected_value"
                   (change)="onCheckboxChange(controlValue, 'create_enote')">
            {{ controlValue.name }}
          </span>
        </span>
      </ng-container>
    </span>
  </span>
  </span>

<ng-container *ngIf="term.isTermTypeChild()">
  <ng-container>

    <div class="row form-group"
         style=" background-color: #efeeee59; border: 1px solid #f2f2f2; border-radius: 3px; ">

      <ng-container *ngIf="term.enable_custom_control && term.term_custom_controls.length > 0">
        <ng-container *ngFor="let term_control of term.term_custom_controls; let i=index">
          <div class="col-sm-3 col-xs-3 form-group pt-sm">
            <ng-container *ngIf="stage == 'create_enote'">
              <app-note-controls [term]="term" [term_control]="term_control" [control_index]="i"
                                 [ui]="'ture'"></app-note-controls>
            </ng-container>
            <ng-container *ngIf="stage == 'drafted_enote'">
              <app-drafted-enote-controls [term]="term" [term_control]="term_control" [control_index]="i"
                                          [drafUi]="'ture'"></app-drafted-enote-controls>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>

  </ng-container>
</ng-container>

<ng-container *ngIf="term.hasChildren()">
  <ng-container *ngFor="let child_terms of term.children_groups;let i=index">
    <span [class.d-block]="i > 0">
      <ng-container *ngFor="let child_term of child_terms">
        <app-term-ui-view class="inline-comp" [stage]="stage" *ngIf="!child_term._destroy"
                          [term]="child_term"></app-term-ui-view>
      </ng-container>
    </span>
  </ng-container>
</ng-container>
