import { Injectable, Output, EventEmitter} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {CustomHttpClient} from './custom-http-client.service';
import {LoaderService} from './loader.service';
import {Template} from '../enote/enote_models/template';
import {Control} from '../enote/enote_models/control';
import {Term} from '../enote/enote_models/term';
import {nullRemover} from './nullRemover';
import {RichTextTemplate} from '../routes/rich-text-template-index/rich-text-template';


@Injectable()
export class EnoteService {
    public template: Template;
    public templates: Template[] = [];
    public templates_listing: any[];
    public term: Term = new Term('Add Term', 0);
    public active_term: Term;
    public control: Control = new Control('+', 0);
    public generic_counter = 0;
    public custom_controls: Control[];
    public default_controls: Control[] = [];
    @Output() term_changes:  EventEmitter<Term> = new EventEmitter<Term>();
    public totalTempaltes: any;
    pageObservable: Subject<any> = new Subject<any>();
    reloadEnotes: Subject<any> = new Subject<any>();
    reloadRichTextTemplate: Subject<RichTextTemplate> = new Subject<RichTextTemplate>();
    public richTextTemplate: RichTextTemplate;



  constructor(public loader: LoaderService, private _tokenService: CustomHttpClient) {
    }

    setActiveTerm(term) {
        this.active_term = term;
        this.term_changes.emit(term);
    }
  getLatestFiveEnotes(patientID: any, limit: number, doctorID?: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('patientID', patientID);
    args = args.set('doctorID', doctorID);
    args = args.set('limit', limit.toString());
    return this._tokenService.get('templates/latest_five_enotes', {params: args})
      .toPromise()
      .then(res => res)
  }
    getDefaultControls(): Promise<any> {
      return this._tokenService.get('search_terms/default_controls')
        .toPromise()
        .then(res => {
          this.default_controls = res.map(c => new Control('', '').load_from_json(c))
        })
    }

    term_search(term_or_keyword, l_id?, l_type?, parent_id?, term?): Observable<any> {
      let args = new HttpParams();
      args = args.set('term', term_or_keyword);
      if (l_id) {
      args = args.set('library_id', l_id);
      }
      if (l_type) {
        args = args.set('library_type', l_type);
      }
      if (term) {
        args = args.set('sibling_ids', term.open_text_uncles.filter(a => a));
      }
        if (parent_id && (l_type == 'component')) {
            args = args.set('parent_id', parent_id);
        }
        return this._tokenService.get('search_terms/search_term_endpont', {params: args})
          .map((resp) => resp);
    }
  search_keyword(term_or_keyword): Observable<any> {
    let args = new HttpParams();
    args = args.set('keyword', term_or_keyword);
    return this._tokenService.get('search_terms/search_keyword', {params: args})
      .map((resp) => resp);
  }

    create(template, is_copy?): Promise<any> {
        return this._tokenService
            .post('templates/', template.to_params(is_copy))
            .toPromise()
            .then(res => template.load_from_json(res))
    }
  create_note(template: Template): Promise<any> {
    return this._tokenService
      .post('templates/note', template.to_params())
      .toPromise()
      .then(res => template.load_from_json(res))
  }
  update_drafted_enote(template): Promise<any> {
    return this._tokenService
      .put('templates/update_drafted_enote', template.to_params())
      .toPromise()
      .then(res => template.load_from_json(res))
  }

  delete_drafted_enote() {
    return this._tokenService
      .delete('templates/' + this.template.id)
      .toPromise()
      .then(res => delete this.template)
  }


  update(template): Promise<any> {
        return this._tokenService
            .put('templates/' + template.id, template.to_params())
            .toPromise()
            .then(res => template.load_from_json(res))
    }

  filterTemplates(filter) {
    let params = new HttpParams({fromObject: nullRemover(filter)});

    return this._tokenService.getWithHeaders('templates/filter', {observe: 'response', params: params, responseType: 'json'}).toPromise()
      .then(response => {
        this.templates_listing = response.body;
        return {
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      });
  }

    get get_next_counter() {
        this.generic_counter = + 1;
        return this.generic_counter;
    }

    getControlsList() {
      return this._tokenService.get('controls/controls_list').toPromise()
            .then(res => res);
    }
  getSingleTemplate(template_id) {

    return this._tokenService.get('templates/' + template_id).toPromise()
      .then(res => {
          return res;

      });
  }

    getTemplate(template_id, readonly?: boolean) {

        return this._tokenService.get('templates/' + template_id).toPromise()
            .then(res => {
              if (readonly) {
                return res;
              }
              else {
                 this.template = new Template().load_from_json(res, this);
              }
            });
    }
    getEmptyTemplate() {

      return this._tokenService.get('templates/empty_template').toPromise()
        .then(res => {
          this.template = new Template().load_from_json(res, this);
        });
    }
    get_templates(): Promise<any>  {
        return this._tokenService.get('templates').toPromise()
            .then(res => res);
    }
    get_public_templates(token: any): Observable<any>   {
      let args = new HttpParams();
      args = args.set('token', token);
      return this._tokenService.get('templates/public_templates', {params: args})
        .map(res => res);
    }
    limited_templates(limit?: any, doctor_id?: any, patientID?: any): Promise<any>  {
      let args = new HttpParams();
      args = args.set('limit', limit.toString());
      if ( doctor_id ) {
        args = args.set('doctor_id', doctor_id.toString());
      }
      if (patientID) {
        args = args.set('patientID', patientID);
      }
      return this._tokenService.get('templates/limited_templates', {params: args}).toPromise()
        .then(res => res);
    }
    get_drafted_enotes(appointmentID) {
      return this._tokenService.get('templates/drafted_enotes?appointmentID=' + appointmentID).toPromise()
        .then(res => res);
    }
    load_templates(json) {
        this.templates = json.map(template => new Template().load_from_json(template));
    }
    fetch_data() {
      return this._tokenService.get('templates/fetch_data').toPromise()
        .then(resp => resp);
    }
    termControlsListing(term) {
      return term.controls;
    }

    templateTermsListing() {
      return this.template.terms;
    }

    currentTemplate() {
      return this.template;
    }

    initializeEnote(term) {
      this.templateTermsListing().push(term);

      this.active_term = term;
    }

    initializeNewControl() {
      let new_control = new Control('+', 0);
      this.control = new_control;
      return this.control;
    }

    loadControlsMultiSelect() {
      let multiSelectOptions = [];
        for (let single_control of this.default_controls) {
            let run_time_object = new Object();
            run_time_object['id'] = single_control.id;
            run_time_object['name'] = single_control.name;
            multiSelectOptions.push(run_time_object)
        }
        return multiSelectOptions;
    }

    filterControl(id) {
      if (this.default_controls && this.default_controls.length > 0) {
        let control = this.default_controls.filter(e => e.id == id)[0];
          if (control.id > 0) {
              control.is_control_applied = true;
              this.default_controls.filter(e => e.id == id)[0] = control;
              return this.default_controls.filter(e => e.id == id)[0];
          }
          return;
      }
    }

    removeChildTerm(deleted_term) {
        deleted_term.deleteYourself();
        // if(deleted_term.parent_id == 'nil') {
        //     let delete_element = this.template.terms.filter(e => e.id == deleted_term.id)
        //     let index_to_delete = this.template.terms.indexOf(delete_element[0]);
        //     if(delete_element.length > 0) {
        //         this.template.terms.splice(index_to_delete, 1);
        //         return false;
        //     }
        // } else {
        //     return this.template.terms.map(term =>
        //         (term.children && term.children.length > 0) ? this.removeChildTermRecursion(term,term.children, deleted_term) : '');
        // }
    }

    removeChildTermRecursion(term, children, deleted_term) {
        let delete_element = children.filter(child_term => (child_term.id == deleted_term.id && child_term.parent_id == deleted_term.parent_id));
        if (delete_element.length > 0) {
          let index_to_delete = children.indexOf(delete_element[0]);
          children.splice(index_to_delete, 1);
          return false;
        }
        children.map(child => (child.children && child.children.length > 0) ? this.removeChildTermRecursion(term, child.children, deleted_term) : '')
    }

    findIndexOfTerm(index_term) {
        return this.template.terms.map(term =>
            (term.children && term.children.length > 0) ? this.getIndex(term, term.children, index_term) : '');
    }

    getIndex(term, children, index_term) {
        let index_element = children.filter(child_term => (child_term.id == index_term.id && child_term.parent_id == index_term.parent_id));
        if (index_element.length > 0) {
            return children.indexOf(index_element[0]);
        }
        children.map(child => (child.children && child.children.length > 0) ? this.getIndex(term, child.children, index_term) : '')
    }

    focusSelectedControl() {
        return this.template.terms.map(term =>
            (term.children && term.children.length > 0) ? this.recuressionControl(term.children, term) : this.unFocusTermControls(term));
    }

    recuressionControl( children: Term[], term?: Term) {
        if (term) {
            term.is_focus = false;
        }
        children.map(termm => termm.is_focus = false);
        children.map(child_term => child_term.controls.map(control => control.is_focus = false));
        children.map(child => (child.children && child.children.length > 0) ? this.recuressionControl(child.children) : '')
    }

    unFocusTermControls(term) {
        term.is_focus = false;
        return term.controls.map(control => control.is_focus = false);
    }

    hideAllMultiselect() {
        for (let term of this.template.terms) {
            for (let control of term.controls) {
                control.show_multiselect = false;
            }
        }
    }

    hideAllControlDefinations() {
        // this.hideAllTypeAheads();
        this.hideAllMultiselect();
        for (let term of this.template.terms) {
            for (let control of term.controls) {
                control.set_control_property = false;
            }
        }
    }

    hideAllTypeAheads() {
        for (let term of this.template.terms) {
            term.show_typeahead = false;
        }
    }

    create_keyword(keyword): Promise<any> {
      return this._tokenService
        .post('templates/keyword', {keyword: keyword.to_params()})
        .toPromise()
        .then(res => res)
    }
  create_term(term): Promise<any> {
    return this._tokenService
      .post('templates/term', {term: term})
      .toPromise()
      .then(res => res)
  }
  update_keyword(keyword): Promise<any> {
    return this._tokenService
      .put('templates/update_keyword/' + keyword.id, {keyword: keyword.to_params()})
      .toPromise()
      .then(res => res)
  }
  delete_keyword(keyword) {
    return this._tokenService
      .delete('templates/delete_keyword/' + keyword.id)
      .toPromise()
      .then(res => res)
  }
  keyword_result_data(keyword_id) {
    let args = new HttpParams();
    args = args.set('keyword_id', keyword_id);
    return this._tokenService
      .get('templates/keyword_result_data', {params: args})
      .toPromise()
      .then(res => res)
  }
  keyword_and_child_result_data(keyword_id) {
    let args = new HttpParams();
    args = args.set('keyword_id', keyword_id);
    return this._tokenService
      .get('templates/keyword_and_child_result_data', {params: args})
      .toPromise()
      .then(res => res)
  }

  getPatientAssignedTemplate(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('templates/templates_of_profile', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          patientEnotes: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }
}
