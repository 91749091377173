<div class="flex-control patient-portal" *ngIf="!showVideo">
  <div class="flex-control">
    <app-patient-panel class="flex-left" *ngIf="patient.id" [appointment]="appointment" [patient]="patient"></app-patient-panel>

    <div  *ngIf="!allFormsCompleted && currentForm" class="flex-control">
      <app-patient-intake-workflow class="flex-body flex-control flex-column " [patient]="patient" [pendingForm]="currentForm" (formCompleted)="onFormCompletion()">
      </app-patient-intake-workflow>
    </div>

    <ng-container *ngIf="allFormsCompleted">
      <app-patient-dashboard class="flex-body flex-control flex-column" *ngIf="patient.id"
                             [appointment]="appointment" [patient]="patient"></app-patient-dashboard>
    </ng-container>

    <div *ngIf="callEnded">
      <p class="alert alert-warning text-center" style="max-width:750px; margin:auto;" *ngIf="showMessage">
        Thank you, your call has been completed. You will receive more instructions via your
        preferred mode of communication. Doctor note will be available for you on patient portal as soon as
        available.
      </p>
    </div>
    <div class="row" style="margin:-20px -15px;" *ngIf="showVideo && !callEnded">
      <app-video-panel (callEnded)="onCallEnded($event)" #video_panel></app-video-panel>
    </div>
  </div>
</div>

<ng-container *ngIf="patientsService.appointments && userService.current_user.isPatient">
  <ng-container *ngFor="let appointment of patientsService.appointments">
    <ng-container *ngIf="appointment.appointment_type === 'video' && appointment.is_today_appointment()">
      <div class="checkin-modal modal" [hidden]="!appointment.showCheckIn">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div class="media">
                <div class="media-left pr-lg">
                  <img class="thumbnail thumb64" [src]="appointment.doctor?.profile_pic.modal.img" alt="Image"/>
                </div>
                <div class="media-body">
                  <h4 class="text-primary mt0">{{appointment.doctor?.complete_name}}</h4>
                  <h5 class="mb-lg">{{appointment.doctor?.designation}}</h5>
                  <h5 class="color-black mt-sm"><i class="fa icon-location-pin vl-m"></i> &nbsp;{{appointment.location?.practice_name}}</h5>
                  <h5 class="color-black mt-sm"
                      *ngIf="appointment.location && appointment.location.contact_numbers && appointment.location.contact_numbers.length > 0">
                    <i class="icon-phone vl-m"></i> &nbsp;{{appointment.location.phone.value}}
                  </h5>
                  <h5 *ngIf="appointment.appointment_type !== 'video'" class="color-black mt-sm">
                    <i class="fa icon-pin vl-m"></i> &nbsp;{{appointment.location?.complete_address}}</h5>
                  <h5 *ngIf="appointment.appointment_type === 'video'" class="color-black mt-sm">
                    <i class="fa icon-pin vl-m"></i> &nbsp; Online Video Consultation
                  </h5>
                </div>
              </div>
              <div class="media m0">
                <div class="media-left" style="min-width:122px;"><h5 class="color-black">Appointment</h5></div>
                <div class="media-body media-middle">
                  <label class="label label-warning fs12">{{appointment.showBookingTimeAndSlot()}}</label>
                </div>
              </div>
              <div class="media m0" *ngIf="appointment.service">
                <div class="media-left" style="min-width:122px;"><h5 class="color-black">Reason of Visit</h5></div>
                <div class="media-body media-middle">
                  <h5 class="fw-500">{{appointment.service?.name}}</h5>
                </div>
              </div>
              <p class="m0 mt white-container-box p pt-sm pb-sm fs13 text-black">
                Your virtual visit with <span>{{appointment.doctor?.complete_name}}</span> is about to start in 10mins, please click
                <span class="text-primary">Check-In</span> button to let <span>{{appointment.doctor?.complete_name}}</span> know you are online.
              </p>
            </div>
            <div class="modal-footer text-center">
              <button class="btn btn-primary" (click)="checkedIn(appointment)">Check In</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
