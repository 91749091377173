<ng-container *ngIf="!ui">
  <ng-container *ngIf="term_control.control.native_type != 'input_text'">
    <div class="status-field enote-multiselect" style="display: inline-block;">
      <ss-multiselect-dropdown [options]="myOptions" [texts]="myTexts" [(ngModel)]="optionsModel"
                               [settings]="selectSettings"
                               (ngModelChange)="selectStatuses()">

      </ss-multiselect-dropdown>
    </div>
  </ng-container>
  <ng-container *ngIf="term_control.control.native_type == 'input_text'">
    <span *ngIf="!showTemp" (click)="showTemp = true;setFocus()"
          style="text-decoration: underline; cursor: pointer">{{ term_control.control.name }} {{ term_control_value?.value?.name }}</span>
    <input style="border: 0" class="pl" [id]="term_control.id"
           *ngIf="showTemp"
           [type]="term_control.control.input_type.toLowerCase()"
           (focusout)="inputFocusout(term_control.control)"
           (keydown.enter)="showTemp = false;"
           [(ngModel)]="term_control_value.value.name">
  </ng-container>
</ng-container>
<ng-container *ngIf="ui">

  <ng-container *ngIf="term_control.control.native_type === 'dropdown'">
    <ng-container *ngIf="term_control.control.name">
      <label>{{ term_control.control.name }}</label>
    </ng-container>
    <ng-container *ngIf="term_control.control.values">
      <ss-multiselect-dropdown class="pl0" [options]="myOptions" [texts]="myTexts" [(ngModel)]="optionsModel"
                               [settings]="selectSettings"
                               (ngModelChange)="selectStatuses()">

      </ss-multiselect-dropdown>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="term_control.control.native_type == 'input_text'">
    <ng-container *ngIf="term_control.control.name">
      <label>{{ term_control.control.name }}</label>
    </ng-container>
    <input class="pl btn-block form-control"
           [id]="term_control.id"
           [type]="term_control.control.input_type.toLowerCase()"
           (focusout)="inputFocusout(term_control.control)"
           [(ngModel)]="term_control_value.value.name">
  </ng-container>

</ng-container>
