import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {Term} from '../../enote_models/term';

@Component({
  selector: 'app-term-ui-view',
  templateUrl: './term-ui-view.component.html',
  styleUrls: ['./term-ui-view.component.scss']
})
export class TermUiViewComponent implements OnInit {
  @Input() term: Term;
  @Input() stage: string;
  @Input() ui: string;

  public textMapper: Object = {'Free Text': 'Enter additional comments', 'Onset': 'Enter full date or month-year', 'medications': 'Medication', 'problems': 'Problem', 'allergies': 'Allergy'};

  constructor(public userService: UserService) {
  }
  ngOnInit(): void {
  }
  showLabel(name: string): string {
    return name === 'Free Text' ? 'Additional information' : name;
  }

  onCheckboxChange(selectedControlValue: any, stage: string): void {
    selectedControlValue.selected_value = !selectedControlValue.selected_value;
    let selectedId: any;
    if (selectedControlValue.selected_value) {
      selectedId = stage === 'drafted_enote' ? selectedControlValue.value.id : selectedControlValue.id;
    } else {
      const noObservationValue = this.term.defaultTermControl.control.values.find(controlValue => controlValue.name === 'no-observation');
      if (noObservationValue) {
        selectedId = noObservationValue.id;
      }
    }
    if (selectedId) {
      this.term.defaultTermControl.control.values.forEach(controlValue => {
        controlValue.selected_value = controlValue.id === selectedId;
      });
    }
    const selectedControl = this.term.defaultTermControl.control.values.find(controlValue => controlValue.selected_value);
    if (selectedControl) {
      this.term.toggle_control_value_form_ui(selectedControl, this.term.defaultTermControl);
    }
  }
}
