export const backendApi = 'https://staging.wellwink.com'
export const environment = {
  production: false,
  url: backendApi,
  versionCheckURL : `${backendApi}/version.json`,
  tinymce: {
    theme_url: '/dist/tinymce'
  },
  phone_number_prefix: '+92',
  recaptcha: {
    v3_siteKey: '6Lc1LacgAAAAALaBebhXCv0zENpdmGWoAB5NplFA',
    v2_siteKey: '6LfoYacgAAAAALQM3DKo6s1Omh7uTSOoUnqXwuIc'
  },
  STRIPE_PUBLIC_KEY: 'pk_test_51LGNcZJA6HSgD3rayU43i2jLu0fID81pnOps75GEgWDGFmPS9H7R9AbuiL0ZOjgaVAqF5pxM4RSUV0ppR4X7h5AF00OxSMLuLq'
};
